import 'bootstrap-datepicker';
/**
 * Created by skoetzing on 13.04.15.
 */
jQuery(function ($) {
    $('#spinnerModal').on('shown.bs.modal', function (e) {
        $('.modal-backdrop').css('opacity', '0.8');
    });
    $('.infoText').popover();
    $(".datepicker").datepicker();

    $('.logo-position-position').click(function () {
        if ($(this).children('.logo-position-description').text().indexOf('right') > 0) {
            $('.previewLogo').each(function () {
                    $(this).prop('src', $(this).prop('src').replace("2", "1"));
            });
        } else {
            $('.previewLogo').each(function () {
                $(this).prop('src', $(this).prop('src').replace("1", "2"));
            });
        }
        $('.logo-position-position').removeClass('logo-position-list-row-active');
        $(this).addClass('logo-position-list-row-active');

        $('.previewLogo').css('left', $(this).data('positioningShowX') + 'px').css('top', $(this).data('positioningShowY') + 'px');
        updateForm($(this).data('positioningPosition'), null);
    });

    function updateForm(positionData, colorData)
    {
        var logoPath = $('#formLogoColor').prop('value');
        if (positionData) {
            $('#formLogoColor').prop('value', logoPath.replace(logoPath.substr(-2, 2), positionData));
            var logoPath = $('#formLogoColor').prop('value');
            if (positionData == 'no') {
                $('#formLogoColor').prop('value', logoPath.replace(logoPath.substr(0, 1), 'n'));
                $('.logo-position-color').hide();
            } else {
                colorData = $('.logo-position-list-row-active.logo-position-color').data('positioningPrintColor');
                $('.logo-position-color').show();
            }
        }
        if (colorData) {
            $('#formLogoColor').prop('value', logoPath.replace(logoPath.substr(0, 1), colorData));
        }
    }

    $('.logo-position-color').click(function () {
        $('.logo-position-color').removeClass('logo-position-list-row-active');
        $(this).addClass('logo-position-list-row-active');

        $('.previewLogo').hide();
        $('#logo' + $(this).data('positioningColor')).show();

        updateForm(null, $(this).data('positioningPrintColor'));
    });

    $('#file').bind('fileuploaddone', function (e, data) {
        $('#nextStep').removeAttr('disabled');
        $('#nextStep').parent().removeClass('disabled');
    });

    $('#confirmation').click(function (e) {
        e.preventDefault();

        // NOTE: Validation also happens in confirmationAction for backend!
        var isValid = function ($form) {
            var $quantity = $form.find('[name="order[quantity]"]'),
                $checkbox = $form.find('[name="acceptedArtworkGuide"][type="checkbox"]'),
                quantity = parseInt($quantity.val(), 10),
                valid = true;

            if (isNaN(quantity) || quantity < 1) {
                $quantity.addClass('f3-form-error').closest('.form-group').addClass('has-error');
                valid = false;
            } else {
                $quantity.removeClass('f3-form-error').closest('.form-group').removeClass('has-error');
            }

            if ($checkbox.prop('checked') === false) {
                $checkbox.closest('.form-group').addClass('has-error');
                valid = false;
            } else {
                $checkbox.closest('.form-group').removeClass('has-error');
            }


            return valid;
        };

        if ($(this).closest('#orderForm').length > 0 && !isValid($(this).closest('form'))) {
            return false;
        }

        var spinner = $('#spinnerModal');
        if (spinner.siblings('.modal').length > 0) {
            var modal = spinner.siblings('.modal').first();
            modal.modal('hide');
        }
        spinner.modal({backdrop: 'static'});
        $('#spinnerModal .spinner').show();
        $('#waitingSpinner').removeClass('hidden');
        var form = $(this).closest('form');
        setTimeout(function () {
            form.submit();
        }, 600);

    });
    $('#previewTeaser').show();
    $(document).on('mouseenter', '.pop-up-box-image-box', function () {
        var source = $(this).children('img').data('id');
        $(this).parents('.modal-dialog').children('.previewBox').children().hide();
        $(this).parents('.modal-dialog').children('.previewBox').children('.preview-' + source).show();
    }).on('mouseleave', '.pop-up-box', function () {
        var id = $(this).parents('.modal').prop('id');
        $(this).parents('.modal-dialog').children('.previewBox').children().hide();
        $('#previewTeaser-' + id).show();
    });

    /*
    if($('.data-check-list-error').length > 0){
        $('#logoPositionStep').prop('disabled', true);
        $('#logoPositionStep').parent().addClass('disabled');
    }
    */

    $('a.submitFormLink').click(function () {
        let form = $(this).closest('form');
        if (!form.data('submitted')) {
            form.data('submitted', true);
            form.submit();
        }

        return false;
    });


    var originalOffsetLeft = $('.navibar .container').offset().left;
    $('#offCanvasIcon').click(function (event) {
        event.preventDefault();
        originalOffsetLeft = $('.navibar .container').offset().left;
        var offCanvasBarWidth = 270;
        $('.overall').show();

        $('#offCanvasBar').show().width(offCanvasBarWidth).css('padding', '0 5px');

        /*var marginLeft = parseInt($('.navibar .container').css('margin-left'));*/
        var offsetLeft = $('.navibar .container').offset().left;
        $('#main .container').css('margin-left', offsetLeft);
        if (offsetLeft - offCanvasBarWidth > 0) {
            $('#main .container').animate({ marginLeft: '+' + (offsetLeft - offCanvasBarWidth) + 'px'}, 300);
        } else {
            $('#main .container').animate({ marginLeft: '-' + ((offsetLeft - offCanvasBarWidth) * -1) + 'px'}, 300);
        }
    });
    $('.overall').click(function () {
        $(this).hide();
        $('#offCanvasBar').hide().width(0);
        $('.container').animate({ marginLeft: '+' + originalOffsetLeft + 'px'}, 250, function () {
            $('.container').css({'margin': '0 auto'});
        });
    });
});

import './animation';
import './effects';
import initIcons from "./fontawesome";

initIcons();

let fileUploadConfigurations = document.querySelectorAll('[data-blueimp-file-upload-configuration]');
[...fileUploadConfigurations].forEach(target => {
    'use strict';
    const configuration = JSON.parse(target.dataset['blueimpFileUploadConfiguration']),
        $element = $('#' + configuration.id);
    import('jquery.fileupload-bootstrap-buttonui').then(() => {
        $element.data('jqData', configuration);
        $element['buttonuploadui']();
    });
});

$('.slideshow-item').each(function () {
    let $this = $(this);

    $this.width($this.find('a').width());
    $this.find('img').width('auto').height($this.height());
});

$('[data-jsInit="setMarginOfDialog"]').each(function (number, selected) {
    $(window).resize(function () {
        let selectorId = selected.dataset.target,
            marginTop = ($('#' + selectorId).height() - $('.modal-dialog').height()) / 2;
        $('#' + selectorId + ' .modal-dialog').css('margin-top', marginTop + 'px');
    }).resize();
});

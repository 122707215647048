import 'jquery';
import 'bootstrap-sass';

$(function () {
    var marginTop = [
        '#panelA2 .middle'
    ];

    var $spinner = $('#previewLoading');
    $spinner.modal({backdrop: 'static'});
    $('#previewLoading .spinner').show();

    var $videoBox = $('#videoBox'),
        panelA1 = $('#panelA1'),
        panelA2 = $('#panelA2'),
        panelB1 = $('#panelB1'),
        panelB2 = $('#panelB2'),
        panelC1 = $('#panelC1'),
        panelC2 = $('#panelC2'),
        panelD1 = $('#panelD1'),
        panelD2 = $('#panelD2'),
        currentPanel = 1,
        animationInProcess = false,
        originalVideoBoxParentWidth = 1128;

    currentPanel = 1;
    function initVideo(callback)
    {
        var $currentPanel = $('*[data-panel="' + currentPanel + '"]'),
            data = $currentPanel.data();

        if (data && data.bleedX && data.bleedY) {
            $videoBox.width(calculateSize(data)['width']).height(calculateSize(data)['height']);
        }
    }

    $(window).resize(function () {
        initVideo(checkForCurrentPanel());
    });

    initVideo(checkForCurrentPanel());

    function panelA()
    {
        animationInProcess = true;
        panelA1.find('.leftHalf').addClass('animated flipA1');
        panelA1.find('.rightHalf').addClass('animated flipA2');
        panelB1.find('.middle').removeClass('hidden');
        panelA1.find('.leftHalf').one(
            'webkitAnimationEnd oanimationend msAnimationEnd animationend',
            function (e) {
                panelA1.find('.leftHalf').removeClass('animated flipA1 ').addClass('hidden');
                panelA1.find('.rightHalf').removeClass('animated flipA2 ').addClass('hidden');
                panelB1.find('.leftHalf').addClass('animated flipB4_B5L_B6').removeClass('hidden');
                panelB1.find('.rightHalf').addClass('animated flipB1_B2R_B3').removeClass('hidden');
                panelB1.find('.leftHalf').one(
                    'webkitAnimationEnd oanimationend msAnimationEnd animationend',
                    function (e) {
                        currentPanel = 2;
                        checkForCurrentPanel(panelB);
                    }
                );
            }
        );
    }
    function panelB()
    {
        panelB1.find('.leftHalf').removeClass('animated flipB4_B5L_B6').addClass('hidden');
        panelB1.find('.rightHalf').removeClass('animated flipB1_B2R_B3').addClass('hidden');
        panelB1.find('.middle').addClass('hidden').removeClass('animated');

        panelB2.find('.topHalf').removeClass('hidden').addClass('animated flipB4_C3_B1');
        panelB2.find('.middle').removeClass('hidden').addClass('animated');
        panelB2.find('.bottomHalf').removeClass('hidden').addClass('animated flipB6_C4_B3');
        panelB2.find('.bottomHalf').one(
            'webkitAnimationEnd MSAnimationEnd oAnimationEnd animationend',
            function (e) {
                panelB2.find('.topHalf').removeClass('animated flipB4_C3_B1').addClass('hidden');
                panelB2.find('.middle').removeClass('animated');
                panelB2.find('.bottomHalf').removeClass('animated flipB6_C4_B3').addClass('hidden');

                panelC1.find('.middleTop').removeClass('hidden').addClass('animated flipC1_C5');
                panelC1.find('.middleBottom').removeClass('hidden').addClass('animated flipC2_C6');
                panelC1.find('.middleBottom').one(
                    'webkitAnimationEnd oAnimationEnd msAnimationEnd animationend',
                    function (e) {
                        panelD1.find('.middle').removeClass('hidden');
                        panelB2.find('.middle').addClass('hidden');
                        panelC1.find('.middleTop').addClass('hidden').removeClass('animated flipC1_C5');
                        panelC1.find('.middleBottom').addClass('hidden').removeClass('animated flipC2_C6');
                        panelC2.find('.leftHalf').removeClass('hidden');
                        panelC2.find('.rightHalf').removeClass('hidden');
                        currentPanel = 3;
                        checkForCurrentPanel(panelC);
                    }
                );

            }
        );
    }
    function panelC()
    {
        panelC2.find('.leftHalf').addClass('animated flipC1_B5_C2');
        panelC2.find('.rightHalf').addClass('animated flipC5_B2_C6');
        panelC2.find('.rightHalf').one(
            'webkitAnimationEnd oanimationend msAnimationEnd animationend',
            function (e) {
                panelC2.find('.leftHalf').removeClass('animated flipC1_B5_C2').addClass('hidden');
                panelC2.find('.rightHalf').removeClass('animated flipC5_B2_C6').addClass('hidden');
                panelD1.find('.middle').removeClass('hidden');
                panelD1.find('.leftHalf').removeClass('hidden').addClass('animated flipD1L_D2L');
                panelD1.find('.rightHalf').addClass('animated flipD1R_D2R').removeClass('hidden');
                panelD1.find('.rightHalf').one(
                    'webkitAnimationEnd oanimationend msAnimationEnd animationend',
                    function (e) {
                        currentPanel = 4;
                        checkForCurrentPanel(panelD);
                    }
                );
            }
        );
    }
    function panelD()
    {
        panelD1.find('.middle').addClass('hidden');
        panelD1.find('.leftHalf').addClass('hidden').removeClass('animated flipD1L_D2L');
        panelD1.find('.rightHalf').addClass('hidden').removeClass('animated flipD1R_D2R');
        panelD2.find('.topHalf').addClass('animated flipD1').removeClass('hidden');
        panelD2.find('.bottomHalf').addClass('animated flipD2').removeClass('hidden');
        panelA2.find('.middle').removeClass('hidden');
        panelD2.find('.topHalf').one(
            'webkitAnimationEnd oanimationend msAnimationEnd animationend',
            function (e) {
                panelD2.find('.topHalf').removeClass('animated flipD1').addClass('hidden');
                panelD2.find('.bottomHalf').removeClass('animated flipD2').addClass('hidden');
                panelA2.find('.topHalf').removeClass('hidden').addClass('animated flipA1T_A2T');
                panelA2.find('.bottomHalf').removeClass('hidden').addClass('animated flipA1B_A2B');
                panelA2.find('.topHalf').one(
                    'webkitAnimationEnd oanimationend msAnimationEnd animationend',
                    function (e) {
                        panelA2.find('.topHalf').addClass('hidden').removeClass('animated flipA1T_A2T');
                        panelA2.find('.bottomHalf').addClass('hidden').removeClass('animated flipA1B_A2B');
                        panelA2.find('.middle').addClass('hidden');
                        panelA1.find('.leftHalf').removeClass('hidden');
                        panelA1.find('.rightHalf').removeClass('hidden');
                        currentPanel = 1;
                        checkForCurrentPanel(panelA);
                    }
                );
            }
        );
    }

    function startAnimation()
    {
        checkForCurrentPanel();
        $videoBox.find('.animated').removeClass('off');
        $('#playAnimation').addClass('hidden');
        $('#pauseAnimation').removeClass('hidden');
        if (!animationInProcess) {
            panelA();
        }
    }
    function pauseAnimation()
    {
        $videoBox.find('.animated').addClass('off');
        $('#playAnimation').removeClass('hidden');
        $('#pauseAnimation').addClass('hidden');
    }

    function checkForCurrentPanel(callback)
    {
        var $currentPanel = $('*[data-panel="' + currentPanel + '"]'),
            data = $currentPanel.data();


        $('.goToPanel').removeClass("preview-shape-image-box-active");
        $currentPanel.addClass("preview-shape-image-box-active");

        if (callback) {
            callback();
        }
    }

    $('#pauseAnimation').click(function () {
        pauseAnimation();
        return false;
    });
    $('#playAnimation').click(function () {
        startAnimation();
        return false;
    });

    $(window).on('load', function () {
        $spinner.modal('hide');
        $('#previewLoading .spinner').hide();

        startAnimation();
    });

    function calculateSize(data)
    {
        var proportion = data.bleedX / data.bleedY;
        if ($videoBox.parent().width() >= originalVideoBoxParentWidth) {
            return {'width': data.bleedX + 'mm', 'height': data.bleedY + 'mm'};
        }
        return {
            'width': ($videoBox.parent().width() * 0.5) + 'px',
            'height': ($videoBox.parent().width() * 0.5 / proportion) + 'px'
        };
    }
});
